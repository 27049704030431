<template>
  <div class="p-fluid">
    <form @submit.prevent="onForgotten">
      <div v-if="!isSubmit">
        <p>{{ $t("forgotten.text") }}</p>
        <div class="p-field">
          <label for="username">{{ $t("forgotten.field_username") }}</label>
          <InputText
            v-model="username"
            aria-label="username"
            aria-describedby="username-error"
            :placeholder="translation.emailPlaceholder"
            :class="{ 'p-invalid': errors['email'] }"
            :disabled="isRequesting"
          />
          <small
            v-if="errors['email']"
            id="username-error"
            class="p-error"
            role="alert"
            >{{ $t("forgotten.field_username_error") }}</small
          >
        </div>
        <div class="p-d-flex">
          <Button
            class="p-button-outlined p-mt-2 p-mr-2 p-mb-2 p-button-lg"
            :label="translation.cancel"
            data-testid="cancel"
            :disabled="isRequesting"
            @click="onCancel"
          />
          <Button
            class="p-mb-2 p-mt-2 p-button-lg"
            type="submit"
            data-testid="submit"
            :label="translation.continue"
            :disabled="isRequesting"
          />
        </div>
      </div>
      <div v-else>
        <h2>{{ $t("forgotten.confirm_title") }}</h2>
        <p>{{ $t("forgotten.confirm_text") }}.</p>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import { assertEmail } from "../../service/assert";
import { USER_FORGET_PASSWORD } from "../../store/modules/auth/auth.actions.type";
import { GET_LOADING_STATUS } from "../../store/modules/global/global.getters.type";

export default {
  name: "ForgottenPassword",
  components: {
    Button,
    InputText,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();
    const translation = {
      cancel: t("common.cancel"),
      continue: t("forgotten.button_continue"),
      emailPlaceholder: t("forgotten.field_username_placeholder"),
    };

    const username = ref("");
    const errors = ref([]);
    const isSubmit = ref(false);
    const isRequesting = computed(() => store.getters[GET_LOADING_STATUS]);

    async function onForgotten() {
      if (validateEmail()) {
        await store.dispatch(USER_FORGET_PASSWORD, username.value);
        await router.push({ name: "login" });
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("forgotten.toast_success"),
          life: TOAST_LIFE,
        });
      }
    }

    function validateEmail() {
      errors.value = [];
      if (!assertEmail(username.value)) {
        errors.value["email"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    const onCancel = async () => await router.push({ name: "login" });

    return {
      username,
      errors,
      isSubmit,
      isRequesting,
      onCancel,
      onForgotten,
      translation,
    };
  },
};
</script>
